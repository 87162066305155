import './App.css';

import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppContext } from './libs/contextLib';
import MyRoutes from './MyRoutes';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <Router>
      <Container className="App">
        <AppContext.Provider value={{ isDarkMode, setIsDarkMode }}>
          <MyRoutes />
        </AppContext.Provider>
      </Container>
    </Router>
  );
}

export default App;
