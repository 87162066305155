import React from 'react';
import { Route, Routes } from 'react-router-dom';

import asyncComponent from './components/AsyncComponent';

const Extension = asyncComponent(() => import("./containers/Extension"));
const Translate = asyncComponent(() => import("./containers/Translate"));
const Page = asyncComponent(() => import("./containers/Page"));

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="extension/*" element={<Extension />} />
      <Route path="translate/*" element={<Translate/>} />
      <Route path="page/*" element={<Page/>} />
    </Routes>
  );
}
