import React, { createContext, useContext } from 'react';

type FADAppContext = {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AppContext = createContext<FADAppContext>({
  isDarkMode: false,
  setIsDarkMode: (value) => { }
});

export function useAppContext() {
  return useContext(AppContext);
}
